<template>
  <v-card id="employees-list">
    <employees-list-add-new
      v-model="isAddNewEmployeesSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refresh="updateValue($event)"
      @refetch-data="fetchEmployees"
    ></employees-list-add-new>
    <!-- search -->
    <v-card-text class="d-flex align-center justify-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5 pt-5">
        <!-- create employees -->
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click.stop="isAddNewEmployeesSidebarActive = !isAddNewEmployeesSidebarActive"
        >
          <v-icon size="18" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Add employees</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5 pt-5">
        <v-btn color="primary" class="mb-4 me-3" @click.stop="chargeClickHandle">
          <v-icon size="18" class="me-1">
            {{ icons.mdiBatteryPlus }}
          </v-icon>
          <span>Charge for employees</span>
        </v-btn>
        <v-btn color="secondary" class="mb-4 me-3" @click.stop="chargeHistoryHandler">
          <v-icon size="18" class="me-1">
            {{ icons.mdiHistory }}
          </v-icon>
          <span>History</span>
        </v-btn>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="employeesListTable"
      :options.sync="options"
      :server-items-length="totalEmployeesListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- client -->
      <template #[`item.firstname`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-employees-view', params: { id: item.mobile_number } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.first_name }}
            </router-link>
          </div>
        </div>
      </template>

      <template #[`item.lastname`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.last_name }}</span>
          </div>
        </div>
      </template>

      <!-- total -->
      <template #[`item.mobile`]="{ item }">
        {{ item.mobile_number }}
      </template>

      <template #[`item.verified`]="{ item }">
        {{ item.user_id ? 'Yes' : 'No' }}
      </template>
      <!-- Balance -->

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-employees-view', params: { id: item.mobile_number } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="handleDelete(item.id, item.company_id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import router from '@/router'
import {
  mdiAccount,
  mdiBatteryPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiHistory,
  mdiPencilOutline,
  mdiPlus,
  mdiTrendingUp,
} from '@mdi/js'

import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref, watchEffect } from '@vue/composition-api'
import Swal from 'sweetalert2'

// store module
import employeesStoreModule from '../employeesStoreModule'
import EmployeesListAddNew from './EmployeesListAddNew.vue'
import useEmployeesList from './useEmployeesList'

// composition function

export default {
  components: {
    EmployeesListAddNew,
  },
  methods: {
    updateValue(data) {
      this.$emit('refresh', data)
    },
  },
  setup(props, { emit }) {
    const EMPLOYEES_APP_STORE_MODULE_NAME = 'app-employees'

    // Register module
    if (!store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEES_APP_STORE_MODULE_NAME, employeesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEES_APP_STORE_MODULE_NAME)
    })

    const {
      employeesListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalEmployeesListTable,
      loading,
      selectedTableData,
      resolveEmployeesStatusVariantAndIcon,
      resolveClientAvatarVariant,
      fetchEmployees,
    } = useEmployeesList()

    const isAddNewEmployeesSidebarActive = ref(false)

    const statusOptions = ref(['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due'])

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const actionOptions = [
      { title: 'Details', icon: mdiPencilOutline },
      { title: 'Edit', icon: mdiDeleteOutline },
    ]

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-employees/removeEmployee', id)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              employeesListTable.value = employeesListTable.value.filter(item => item.id !== id)
            })
            .catch(error => {
              console.log('error.response.data', error.response.data)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                // footer: 'This Chef might have items that needs to be deleted first',
              })
            })
        }
      })
    }

    const chargeClickHandle = () => {
      const { id } = router.currentRoute.params
      router.push({
        path: `/apps/charge/list/?id=${id}`,
      })
    }

    const chargeHistoryHandler = () => {
      const { id } = router.currentRoute.params
      router.push({ path: `/apps/charge-history/list/?id=${id}` })
    }

    return {
      tableColumns,
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalEmployeesListTable,
      employeesListTable,
      loading,
      actions,
      selectedTableData,
      actionOptions,
      selectedAction,
      isAddNewEmployeesSidebarActive,
      roleOptions,
      planOptions,
      checkType,
      avatarText,
      resolveEmployeesStatusVariantAndIcon,
      resolveClientAvatarVariant,
      fetchEmployees,
      handleDelete,
      chargeClickHandle,
      chargeHistoryHandler,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiHistory,
        mdiBatteryPlus,
        mdiAccount,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#employees-list {
  .employees-list-actions {
    max-width: 7.81rem;
  }

  .employees-list-search {
    max-width: 10.625rem;
  }

  .employees-list-status {
    max-width: 11.3rem;
  }
}
</style>
