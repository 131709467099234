<template>
  <v-navigation-drawer
    :value="isAddNewEmployeesSidebarActive"
    :permanent="isAddNewEmployeesSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-employees-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Employee</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-employees-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="employeesData.first_name"
            outlined
            dense
            :rules="[validators.required]"
            label="First Name"
            placeholder="John"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="employeesData.last_name"
            :rules="[validators.required]"
            outlined
            dense
            label="Last Name"
            placeholder="Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="employeesData.mobile_number"
            :rules="[validators.required , validators.integerValidator]"
            outlined
            dense
            label="Mobile Number"
            placeholder="0791152439"
            hide-details="auto"
            class="mb-6"
            type="number"
            hide-spin-buttons
          ></v-text-field>

          <v-text-field
            v-model="employeesData.email"
            :rules="[validators.required , validators.emailValidator]"
            outlined
            dense
            label="Email"
            placeholder="example@something.com"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="employeesData.employee_number"
            :rules="[validators.required , validators.integerValidator]"
            outlined
            dense
            label="Employee Number"
            placeholder="Employee Number"
            hide-details="auto"
            class="mb-6"
            type="number"
            hide-spin-buttons
          ></v-text-field>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetemployeesData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import router from '@/router'
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddNewEmployeesSidebarActive',
    event: 'update:is-add-new-employees-sidebar-active',
  },
  props: {
    isAddNewEmployeesSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const { emit } = context

    const blankEmployeesData = {
      first_name: '',
      last_name: '',
      mobile_number: '',
      emergency_number: '',
      email: '',
      company_id: router.currentRoute.params.id,
      delivery_factor: '',
      employee_number: '',
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const employeesData = ref(JSON.parse(JSON.stringify(blankEmployeesData)))

    const resetemployeesData = () => {
      employeesData.value = JSON.parse(JSON.stringify(blankEmployeesData))
      resetForm()
      emit('update:is-add-new-employees-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-employees/addEmployee', employeesData.value)
          .then(r => {
            emit('refetch-data')
            emit('update:is-add-new-employees-sidebar-active', false)
            emit('refresh', r.data.data.company_employee)
          })
          .catch(e => {
            if (e?.response?.status === 404) {
              resetemployeesData()
            }
          })

        resetemployeesData()
      } else {
        validate()
      }
    }

    return {
      resetemployeesData,
      form,
      onSubmit,
      countries,
      employeesData,
      valid,
      validate,

      // validation
      validators: { required, emailValidator, integerValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
