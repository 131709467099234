import router from '@/router'
import store from '@/store'
import {
  mdiAlertCircleOutline,
  mdiChartTimelineVariant,
  mdiCheckCircleOutline,
  mdiClose,
  mdiContentSaveOutline,
  mdiDownloadCircleOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useEmployeesList() {
  const employeesListTable = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'FIRST NAME', value: 'firstname' },
    { text: 'LAST NAME', value: 'lastname' },
    { text: 'MOBILE NUMBER', value: 'mobile' },
    { text: 'VERIFIED', value: 'verified' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const totalEmployeesListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const fetchEmployees = () => {
    if (router.currentRoute.params.id) {
      options.value.company_id = router.currentRoute.params.id
      console.log('router.currentRoute.params.id', router.currentRoute.params.id)
      store
        .dispatch('app-employees/fetchEmployees', {
          q: searchQuery.value,
          options: options.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { filteredData, total } = response.data.data
          employeesListTable.value = filteredData
          console.log('employeesListTable.value', filteredData)
          totalEmployeesListTable.value = total
          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }else{
      loading.value = false
    }
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchEmployees()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEmployeesStatusVariant = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: mdiChartTimelineVariant }
    if (status === 'Paid') return { variant: 'success', icon: mdiCheckCircleOutline }
    if (status === 'Downloaded') return { variant: 'info', icon: mdiDownloadCircleOutline }
    if (status === 'Draft') return { variant: 'secondary', icon: mdiContentSaveOutline }
    if (status === 'Sent') return { variant: 'primary', icon: mdiEmailOutline }
    if (status === 'Past Due') return { variant: 'error', icon: mdiAlertCircleOutline }

    return { variant: 'secondary', icon: mdiClose }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'error'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'

    return 'primary'
  }

  return {
    tableColumns,
    searchQuery,
    options,
    employeesListTable,
    statusFilter,
    totalEmployeesListTable,
    loading,
    selectedTableData,
    fetchEmployees,
    resolveEmployeesStatusVariant,
    resolveClientAvatarVariant,
  }
}
