import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"employees-list"}},[_c('employees-list-add-new',{attrs:{"role-options":_vm.roleOptions,"plan-options":_vm.planOptions},on:{"refresh":function($event){return _vm.updateValue($event)},"refetch-data":_vm.fetchEmployees},model:{value:(_vm.isAddNewEmployeesSidebarActive),callback:function ($$v) {_vm.isAddNewEmployeesSidebarActive=$$v},expression:"isAddNewEmployeesSidebarActive"}}),_c(VCardText,{staticClass:"d-flex align-center justify-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5 pt-5"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewEmployeesSidebarActive = !_vm.isAddNewEmployeesSidebarActive}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Add employees")])],1)],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5 pt-5"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.chargeClickHandle.apply(null, arguments)}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiBatteryPlus)+" ")]),_c('span',[_vm._v("Charge for employees")])],1),_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.chargeHistoryHandler.apply(null, arguments)}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHistory)+" ")]),_c('span',[_vm._v("History")])],1)],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.employeesListTable,"options":_vm.options,"server-items-length":_vm.totalEmployeesListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-employees-view', params: { id: item.mobile_number } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])],1)])]}},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.last_name))])])])]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mobile_number)+" ")]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_id ? 'Yes' : 'No')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'apps-employees-view', params: { id: item.mobile_number } }}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.handleDelete(item.id, item.company_id)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }