import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/companies/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployees(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/company-employees?options={"company_id":${params.options.company_id}}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/company-employees/create', employeeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEmployee(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/company-employees/delete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchEmployee(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/company-employees/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/company-employees/update', employeeData)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    chargeEmployees(ctx, chargeData) {
      console.log('chargeData',chargeData);
      return new Promise((resolve, reject) => {
        axios
          .post('/charge', chargeData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
  },
}
